import { render, staticRenderFns } from "./GameItem2.vue?vue&type=template&id=3330e33e&scoped=true"
import script from "./GameItem2.vue?vue&type=script&lang=js"
export * from "./GameItem2.vue?vue&type=script&lang=js"
import style0 from "./GameItem2.vue?vue&type=style&index=0&id=3330e33e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3330e33e",
  null
  
)

export default component.exports