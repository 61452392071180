<template>
    <div class="game-item-component" @click="toGameDetails(game.gameId)">
        <div v-if="game.gameId" class="cover">
            <img class="img1" :src="'/images/gfg_y.png'" />
            <img class="img2" v-lazy="imgFn(game)" :data-id="game.gameId" :alt="game.name" />
        </div>
        <div class="info-wrap">
            <!-- <span>{{ $t("games_BoxFirst") }}</span> -->
            <div class="name">{{ filterName(game, 'game') }}</div>
            <p class="time">{{ game.date }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GameItem',
    data() {
        return {
            lang: localStorage.getItem("LANG_NAME")
        }
    },
    props: {
        currentType: {
            type: String || Number,
            default: ''
        },
        itemActive: {
            type: String || Number,
            default: ''
        },
        parseVal: {
            type: Array,
            default: () => []
        },
        form: {
            type: String,
            default: ''
        },
        game: {
            type: Object,
            require: true,
            default: () => {
                return {
                    // 游戏id
                    id: 0,
                    // 名称
                    name: '',
                    // 时间
                    time: ''
                    
                };
            },
        }
    },
    methods: {
        filterName(val, key) {
            const item = val.descriptionData.find(ele => ele.name === this.lang)
            console.log(item, val, this.lang, 'itemitemitemitemitemitem')
            return item[key]
        },
        imgFn(val) {
            console.log(val, 'valalsdlasldlasd')
            const item = val.phoneData.find(ele => ele.name2 === '封面')
            if (this.lang === '简体中文') {
                return 'https://gfgadmin.dr5kr.live/td/api/photo/' + item.chinese
            } else {
                return 'https://gfgadmin.dr5kr.live/td/api/photo/' + item.english
            }
        },
        toGameDetails(id) {
            this.$router.push({
                path: '/details',
                query: {
                    id,
                    currentType: this.currentType,
                    itemActive: this.itemActive
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.game-item-component {
    // background-color: #1E1E1E;
    border-radius: 25px;
    overflow: hidden;
    color: #fff;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,.3);

    .info-wrap {
        background-color: #201818;
        padding: 24px 30px 30px;
        font-size: 12px;
        // background-color: blanchedalmond;
    }

    .cover {
        height: 293px;
        position: relative;
        img {
            width: 100%;
            height: 293px;
        }
        .img1 {
            width: 100%;
            position: absolute;
            z-index: 1;
        }
        .img2 {
            width: 100%;
            position: absolute;
            z-index: 2;
        }
    }
    .swiperImg {
        img {
            // width: 486px;
            height: 750px;
        }
    }

    .name {
        margin-bottom: 6px;
        font-size: 30px;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-y: hidden;
        line-height: 45px;
    }
    .time {
        font-size: 24px;
        font-family: 'fontTitle';
    }
    ::-webkit-scrollbar {
        display: none;
    }
    
}
</style>