import { render, staticRenderFns } from "./allgames.vue?vue&type=template&id=68bbf692&scoped=true"
import script from "./allgames.vue?vue&type=script&lang=js"
export * from "./allgames.vue?vue&type=script&lang=js"
import style0 from "./allgames.vue?vue&type=style&index=0&id=68bbf692&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68bbf692",
  null
  
)

export default component.exports